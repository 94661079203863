import { Icon } from "@iconify/react";
import Spinner from "@/Components/System/Spinner/spinner";
import FrontText from "../FrontText";

const FrontRoundedButton = ({
    className = "",
    type = "button",
    children,
    disabled,
    displayAsButton = false,
    ...props
}) => {
    const styles = `inline-flex bg-white stroke-blue-classic text-blue-classic border-blue-classic hover:stroke-white hover:text-white hover:bg-blue-classic items-center px-4 py-[9px] border rounded-25 gap-[10px] transition ease-in-out duration-150 hover:opacity-90 ${
        disabled && "opacity-50 cursor-not-allowed"
    } ${className}`

    if (displayAsButton) {
        return (
            <button
                {...props}
                type={type}
                className={styles}
                disabled={disabled}
            >
                <FrontText textSize={"16/20-m-same"} className="font-bold">
                    {children}
                </FrontText>
    
                <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 1.5L11 8.31818L2 16.5"
                        strokeWidth="3"
                    />
                </svg>
            </button>
        );
    } else {

    return (
        <div
            {...props}
            className={styles}
        >
            <FrontText textSize={"16/20-m-same"} className="font-bold">
                {children}
            </FrontText>

            <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 1.5L11 8.31818L2 16.5"
                    strokeWidth="3"
                />
            </svg>
        </div>
    );
    }
};

export default FrontRoundedButton;
